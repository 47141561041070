import ArecaDehusker from '../assets/images/categories/ARECA EQUIPMENS/ARECA DEHUSKER.jpeg';
import ArecaDehusker2 from '../assets/images/categories/ARECA EQUIPMENS/ARECA DEHUSKER2.jpeg';
import ARECATREECLIMBER from '../assets/images/categories/ARECA EQUIPMENS/ARECA TREE CLIMBER.png';
import BUNCHSEPERATOR from '../assets/images/categories/ARECA EQUIPMENS/BUNCH SEPERATOR.jpeg';
import DHOTI from '../assets/images/categories/ARECA EQUIPMENS/DHOTI.jpg';
import EARTHAUGER from '../assets/images/categories/ARECA EQUIPMENS/EARTH AUGER.jpeg';
import GORBALPOLISHER from '../assets/images/categories/ARECA EQUIPMENS/GORBAL POLISHER.jpeg';
import HTPSPRAYER from '../assets/images/categories/ARECA EQUIPMENS/HTP SPRAYER.jpeg';
import LADDER from '../assets/images/categories/ARECA EQUIPMENS/LADDER.jpeg';
import MOTOCART125 from '../assets/images/categories/ARECA EQUIPMENS/MOTOCART 125.jpg';
import PLATEMAKINGMACHINE from '../assets/images/categories/ARECA EQUIPMENS/PLATE MAKING MACHINE.jpeg';
import REJECTIONDEHUSKER from '../assets/images/categories/ARECA EQUIPMENS/REJECTION DEHUSKER.jpeg';
import motocart350 from '../assets/images/categories/ARECA EQUIPMENS/motocart 350.png';
import woodchipper from '../assets/images/categories/ARECA EQUIPMENS/woodchipper.png';
import SolarDryer2 from '../assets/images/categories/ARECA EQUIPMENS/Solar Dryer2.jpg';
import HONDAUMK435 from '../assets/images/categories/BRUSHCUTTER/HONDA UMK-435.png';
import HUSQVARNABRUSHCUTTER from '../assets/images/categories/BRUSHCUTTER/HUSQVARNA BRUSHCUTTER.jpeg';
import JAGVARWEEDCUTTERJR860 from '../assets/images/categories/BRUSHCUTTER/JAGVAR WEED CUTTER – JR860.jpg';
import KKBC8640 from '../assets/images/categories/BRUSHCUTTER/KK-BC-8640.jpg';
import OLEOMACSPARTA44N from '../assets/images/categories/BRUSHCUTTER/OLEOMAC SPARTA 44 N.png';
import SUNKRAFTGI45 from '../assets/images/categories/BRUSHCUTTER/SUNKRAFT GI45.png';
import maijoBC520B from '../assets/images/categories/BRUSHCUTTER/maijo BC 520B.png';
import makitaem3400ubrushcutter from '../assets/images/categories/BRUSHCUTTER/makita-em3400u-brush-cutter-.webp';
import KASEI from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/KASEI.png';
import MAKITAUR3501 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/MAKITA UR3501.jpg';
import SUNKRAFT from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/SUNKRAFT.png';
import cubcadet from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/cub cadet.jpeg';
import DSC_7661copy from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/BLADES/DSC_7661 copy.jpg';
import DSC_7682 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/BLADES/DSC_7682 copy.jpg';
import DSC_7716copy from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/BLADES/DSC_7716 copy.jpg';
import DSC_7679 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/ROPE/DSC_7679 copy.jpg';
import DSC_7626 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/TAP N GO/DSC_7626 copy.png';
import DSC_7644 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/TAP N GO/DSC_7644 copy.png';
import DSC_7648 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/TAP N GO/DSC_7648 copy.png';
import BELT from '../assets/images/categories/BRUSHCUTTER/ACCESSORIES/ACCESSORIES/BELT.jpg';
import FACEGUARD from '../assets/images/categories/BRUSHCUTTER/ACCESSORIES/ACCESSORIES/FACEGUARD.jpeg';
import GLASS from '../assets/images/categories/BRUSHCUTTER/ACCESSORIES/ACCESSORIES/GLASS.jpeg';
import KARCHERK2350CAP from '../assets/images/categories/CAR WASHER/KARCHER K2 350 CAP.jpg';
import KISAKRAFTKKPWIM007 from '../assets/images/categories/CAR WASHER/KISAKRAFT KKPWIM007.jpg';
import MAKITHAHW102 from '../assets/images/categories/CAR WASHER/MAKITHA HW102.jpg';
import SUNKRAFTHTP from '../assets/images/categories/CAR WASHER/SUNKRAFT HTP.jpeg';
import sunkraftSK1 from '../assets/images/categories/CAR WASHER/sunkraft SK1.jpeg';
import sunkraftSK170 from '../assets/images/categories/CAR WASHER/sunkraft SK170.png';
import HONDAWB20XD from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/HONDA WB20XD.jpeg';
import HONDAWB30XD from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/HONDA WB30XD.jpeg';
import KISANKRAFT3HPkkwpp21 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/KISANKRAFT 3HP kkwpp21.jpeg';
import KISANKRAFT5HPKKWPDV178 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/KISANKRAFT 5HP KK-WPDV-178.jpg';
import SUNKRAFTSKWPP30 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/SUNKRAFT SKWPP30.jpeg';
import GX35 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/GX35.jpg';
import GX80 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/GX80-.png';
import brigGS3HP from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/brigGS 3HP.jpeg';
import gx160 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/gx160.png';
import ULTIMA22 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/PUMP/ULTIMA 22.jpeg';
import ULTIMA30 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/PUMP/ULTIMA 30.jpeg';
import HUSQVARNACHAINSAW from '../assets/images/categories/chainsaw/HUSQVARNA CHAINSAW.jpeg';
import himaxchainsaw from '../assets/images/categories/chainsaw/hi max chain saw.jpg';
import jagvar from '../assets/images/categories/chainsaw/jagvar.png';
import stihl from '../assets/images/categories/chainsaw/stihl.webp';
import CANFLY from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/CANFLY.jpeg';
import MINISTIHL from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/MINI STIHL.jpeg';
import OREGON from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/OREGON.jpeg';
import xlent from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/xlent.jpeg';
import BAR from '../assets/images/categories/chainsaw/ACCESSORIES/BAR.png';
import CHAINFILINGMACHINE from '../assets/images/categories/chainsaw/ACCESSORIES/CHAIN FILING MACHINE.jpeg';
import CHAIN from '../assets/images/categories/chainsaw/ACCESSORIES/CHAIN.jpeg';
import DOUBLECLUSTERMILKINGMACHINE from '../assets/images/categories/dairy products/DOUBLE CLUSTER MILKING MACHI NE.jpg';
import MANUALMILKINGMACHINE from '../assets/images/categories/dairy products/MANUAL MILKING MACHINE.png';
import cowmat from '../assets/images/categories/dairy products/cowmat.jpg';
import curdchurner from '../assets/images/categories/dairy products/curd-churner.png';
import singleclusterMILKINGMACHINE from '../assets/images/categories/dairy products/single cluster MILKING MACHINE.jpeg';
import BHOOMI from '../assets/images/categories/dairy products/CHAFFCUTTER/BHOOMI.jpeg';
import EASYMINI from '../assets/images/categories/dairy products/CHAFFCUTTER/EASY MINI.jpeg';
import KKFMC500WITH3hpMotor from '../assets/images/categories/dairy products/CHAFFCUTTER/KK-FMC-500-WITH-3hp-Motor.jpg';
import ManualMilkingMachine from '../assets/images/categories/dairy products/CHAFFCUTTER/Manual-Milking-Machine.webp';
import KARCHERK2350CAP1 from '../assets/images/categories/dairy products/PRESSURE WASHER/KARCHER K2 350 CAP.jpg';
import KISAKRAFTKKPWIM0071 from '../assets/images/categories/dairy products/PRESSURE WASHER/KISAKRAFT KKPWIM007.jpg';
import MAKITHAHW1021 from '../assets/images/categories/dairy products/PRESSURE WASHER/MAKITHA HW102.jpg';
import sunkraftSK11 from '../assets/images/categories/dairy products/PRESSURE WASHER/sunkraft SK1.jpeg';
import sunkraftSK1701 from '../assets/images/categories/dairy products/PRESSURE WASHER/sunkraft SK170.png';
import AXE from '../assets/images/categories/garden EQUIPMENTS/AXE.jpg';
import ELECTRICHEDGETRIMMER from '../assets/images/categories/garden EQUIPMENTS/ELECTRIC HEDGE TRIMMER.webp';
import FALCON from '../assets/images/categories/garden EQUIPMENTS/FALCON .jpg';
import GARDENINGTOOLS from '../assets/images/categories/garden EQUIPMENTS/GARDENING TOOLS.jpg';
import HEDGESHEAR from '../assets/images/categories/garden EQUIPMENTS/HEDGE SHEAR.webp';
import LAWNMOWER from '../assets/images/categories/garden EQUIPMENTS/LAWNMOWER.jpeg';
import MANUALWEEDER from '../assets/images/categories/garden EQUIPMENTS/MANUAL WEEDER.jpeg';
import MANUALsprayerS from '../assets/images/categories/garden EQUIPMENTS/MANUAL sprayerS.png';
import ROOTPULLER from '../assets/images/categories/garden EQUIPMENTS/ROOT PULLER.png';
import ROSECAN from '../assets/images/categories/garden EQUIPMENTS/ROSE CAN.jpg';
import SHADENET from '../assets/images/categories/garden EQUIPMENTS/SHADENET.jpeg';
import SPRAYER from '../assets/images/categories/garden EQUIPMENTS/SPRAYER.png';
import STUBBLEMOWER from '../assets/images/categories/garden EQUIPMENTS/STUBBLE MOWER.jpg';
import Weedmat from '../assets/images/categories/garden EQUIPMENTS/Weedmat.jpeg';
import cubcadet1 from '../assets/images/categories/garden EQUIPMENTS/cub cadet.jpeg';
import easycut from '../assets/images/categories/garden EQUIPMENTS/easycut.png';
import fruitpicker from '../assets/images/categories/garden EQUIPMENTS/fruit picker.png';
import hectareproducts from '../assets/images/categories/garden EQUIPMENTS/hectare products.jpg';
import snakecatcher from '../assets/images/categories/garden EQUIPMENTS/snake-catcher.webp';
import GROWBAG from '../assets/images/categories/garden EQUIPMENTS/GROW BAG.jpg';
import HaneyEquipHoneyBeeHiveJunglewoodBox01 from '../assets/images/categories/HONEY EQUIPMENTS/Haney-Equip-Honey-Bee-Hive-Jungle-wood-Box-01 (1).jpg';
import HaneyEquipHoneyNanoBeeHiveBox01 from '../assets/images/categories/HONEY EQUIPMENTS/Haney-Equip-Honey-Nano-Bee-Hive-Box-01.jpg';
import HaneyEquipHoneyextractorsGalvanizedIronSheet from '../assets/images/categories/HONEY EQUIPMENTS/Haney-Equip-Honey-extractors-Galvanized-Iron-Sheet.jpg';
import HoneyEquipBeeSmoker01 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Bee-Smoker-01.jpg';
import HoneyEquipBeeVeil01 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Bee-Veil-01.jpg';
import HoneyEquipHoneyBeeHive02 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Honey-Bee-Hive-02.jpg';
import HoneyEquipProductsIronStand01 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Products-Iron-Stand-01.jpg';
import HoneyEquipQueenGate02 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Queen-Gate-02.jpg';
import HoneyEquipQueenGate03 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Queen-Gate-03 (1).jpg';
import cutehoneybeestickersforcaroranywherestickerbumperstickeralibees782517_grande from '../assets/images/categories/HONEY EQUIPMENTS/cute-honey-bee-stickers-for-car-or-anywhere-stickerbumper-sticker-ali-bees-782517_grande.jpg';
import MONOBLOCPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/MONOBLOC  PUMP.jpg';
import SLURRYPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/SLURRY PUMP.jpg';
import SUBMERSIBLEPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/SUBMERSIBLE PUMP.jpg';
import WATERPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/WATER PUMP.webp';
import WATERTANK from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/WATER TANK.webp';
import dripitems from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/drip items.jpg';
import finolexpipes from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/finolex pipes.jpg';
import multistagepump from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/multistage pump.jpeg';
import sprinkler from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/sprinkler.jpeg';
import COCONUTHUSKPEELER from '../assets/images/categories/OTHER EQUPMENTS/COCONUT HUSK PEELER.jpeg';
import GENERATOR from '../assets/images/categories/OTHER EQUPMENTS/GENERATOR.jpg';
import LADDER1 from '../assets/images/categories/OTHER EQUPMENTS/LADDER.jpg';
import MS5000MiniGasFogger from '../assets/images/categories/OTHER EQUPMENTS/MS-5000 Mini Gas Fogger.jpg';
import STUBBLEMOWER1 from '../assets/images/categories/OTHER EQUPMENTS/STUBBLE MOWER.jpg';
import Weedmat1 from '../assets/images/categories/OTHER EQUPMENTS/Weedmat.jpeg';
import blower from '../assets/images/categories/OTHER EQUPMENTS/blower.webp';
import boiler from '../assets/images/categories/OTHER EQUPMENTS/boiler.jpg';
import coconutclimber from '../assets/images/categories/OTHER EQUPMENTS/coconut climber.png';
import cylindertrolley from '../assets/images/categories/OTHER EQUPMENTS/cylinder trolley.jpg';
import easycut1 from '../assets/images/categories/OTHER EQUPMENTS/easycut.png';
import foggingmachine from '../assets/images/categories/OTHER EQUPMENTS/fogging machine.jpeg';
import karchervaccumcleaner from '../assets/images/categories/OTHER EQUPMENTS/karcher vaccum cleaner.webp';
import mistblower from '../assets/images/categories/OTHER EQUPMENTS/mist blower.png';
import raincover from '../assets/images/categories/OTHER EQUPMENTS/rain cover.jpg';
import shadenet from '../assets/images/categories/OTHER EQUPMENTS/shadenet.png';
import solartrap from '../assets/images/categories/OTHER EQUPMENTS/solar trap.png';
import CONOWEEDER from '../assets/images/categories/paddy equipments/CONOWEEDER.png';
import DRUMSEEDER from '../assets/images/categories/paddy equipments/DRUM SEEDER.jpeg';
import MANUALSEEDER from '../assets/images/categories/paddy equipments/MANUAL SEEDER.webp';
import NURSERYTRAY from '../assets/images/categories/paddy equipments/NURSERY TRAY.png';
import ROTARYTILLER from '../assets/images/categories/paddy equipments/ROTARY TILLER.jpeg';
import powertiller from '../assets/images/categories/paddy equipments/power tiller.png';
import reaper from '../assets/images/categories/paddy equipments/reaper.jpeg';
import tractor20hp60hp from '../assets/images/categories/paddy equipments/tractor 20hp-60hp.jpeg';
import transplanter from '../assets/images/categories/paddy equipments/transplanter.jpg';
import BALER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/BALER.png';
import DOZERBACKHOELOADER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/DOZER BACKHOE & LOADER.webp';
import DUCKFOOTCULTIVATOR from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/DUCKFOOT CULTIVATOR.jpg';
import MOBILESHREDDER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/MOBILE SHREDDER.png';
import MULCHER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/MULCHER.png';
import POSTHOLEDIGGER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/POST HOLE DIGGER.png';
import SPRINGCULTIVATOR from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/SPRING CULTIVATOR..png';
import SUGARCANESTUBBLESHAVER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/SUGARCANE STUBBLE-SHAVER.png';
import rotovator from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/rotovator.png';
import GREENPEPPERPEELINGMACHINE05HP from '../assets/images/categories/pepper equipmens/0.5 HP GREEN PEPPER PEELING MACHINE.png';
import CAT960PEPPERPEELINGMACHINE1HP from '../assets/images/categories/pepper equipmens/1 HP CAT 960 PEPPER PEELING MACHINE.png';
import PEPPERPEELINGMACHINE1HP from '../assets/images/categories/pepper equipmens/1HP_PEPPER-PEELING MACHINE.png';
import CAT9702HP from '../assets/images/categories/pepper equipmens/2HP CAT 970.jpg';
import PEPPERPEELINGMACHINE05hp from '../assets/images/categories/pepper equipmens/0.5 hp PEPPER PEELING MACHINE.jpg';
import BUNCHSEPERATOR1 from '../assets/images/categories/pepper equipmens/BUNCH SEPERATOR.jpg';
import ANGLEGRINDER from '../assets/images/categories/power tools/ANGLE GRINDER.jpeg';
import ELECTRICHANDHELDPRUNER from '../assets/images/categories/power tools/ELECTRIC HANDHELD PRUNER.jpg';
import HAMMERDRILL from '../assets/images/categories/power tools/HAMMER DRILL.jpg';
import IMPACTDRIVER from '../assets/images/categories/power tools/IMPACT DRIVER.jpg';
import MAKITAELECTRICBRUSHCUTTER from '../assets/images/categories/power tools/MAKITA ELECTRIC BRUSHCUTTER.jpg';
import chainfiling from '../assets/images/categories/power tools/chain filing.png';
import drillM0600B from '../assets/images/categories/power tools/drill  M0600B.jpg';
import makitaCORDLESSCHAINSAW from '../assets/images/categories/power tools/makita CORDLESS CHAINSAW.png';
import vaccumcleaner from '../assets/images/categories/power tools/vaccum, cleaner.jpeg';
import HTPSPRAYER168F from '../assets/images/categories/SPRAYERS/168F HTP SPRAYER.png';
import GATORROCKINGSPRAYER from '../assets/images/categories/SPRAYERS/GATOR-ROCKING-SPRAYER.png';
import GX80SK22 from '../assets/images/categories/SPRAYERS/GX80+SK22.webp';
import KISANKRAFTKKPS22 from '../assets/images/categories/SPRAYERS/KISANKRAFT KKPS22.jpg';
import KISANKRAFTKKPS30 from '../assets/images/categories/SPRAYERS/KISANKRAFT KKPS30.jpg';
import SUNKRAFTSK768 from '../assets/images/categories/SPRAYERS/SUNKRAFT SK768.png';
import SUNKRAFTSKPSP30 from '../assets/images/categories/SPRAYERS/SUNKRAFT SKPSP30.jpeg';
import SUNKRAFT_SK868 from '../assets/images/categories/SPRAYERS/SUNKRAFT_SK868.png';
import SUNKRAFT_SKPS18 from '../assets/images/categories/SPRAYERS/SUNKRAFT_SKPS18.png';
import SUNKRAFT_SKPSP22 from '../assets/images/categories/SPRAYERS/SUNKRAFT_SKPSP22.png';
import ULTIMA555 from '../assets/images/categories/SPRAYERS/ULTIMA 555.jpg';
import ULTIMAPOWERSPRAYER from '../assets/images/categories/SPRAYERS/ULTIMA POWER SPRAYER.jpeg';
import Ultima3HPHTPSprayerUL22 from '../assets/images/categories/SPRAYERS/Ultima 3HP HTP Sprayer UL22.jpeg';
import EcoSinglewheel from '../assets/images/categories/WHEEL BORROW/Eco Single wheel.png';
import EcoTwoWheelWLB2TR from '../assets/images/categories/WHEEL BORROW/Eco Two Wheel WLB2TR.png';
import MTNWB3Mathana3Wheelbarrow from '../assets/images/categories/WHEEL BORROW/MTNWB3 Mathana 3 Wheel barrow.png';
import PushcartThreeWheelWithBreakupPCO3WWB from '../assets/images/categories/WHEEL BORROW/Push cart Three Wheel With Breakup PCO3WWB.png';

const products = [
  {
    id: '1',
    name: 'Areca Dehusker',
    category: 'Areca-Equipments',
    subcategory: null,
    image: ArecaDehusker,
    description: 'Automates the process of removing the husk from areca nuts, increasing efficiency and saving time.',
  },
  {
    id: '2',
    name: 'Areca Dehusker2',
    category: 'Areca-Equipments',
    subcategory: null,
    image: ArecaDehusker2,
    description: 'Automates the process of removing the husk from areca nuts, increasing efficiency and saving time.',
  },
  {
    id: '5',
    name: 'Dhoti',
    category: 'Areca-Equipments',
    subcategory: null,
    image: DHOTI,
    description: 'A durable and comfortable tool for traditional areca farming tasks.',
  },
  {
    id: '12',
    name: 'Rejection Dehusker',
    category: 'Areca-Equipments',
    subcategory: null,
    image: REJECTIONDEHUSKER,
    description: 'Removes rejected husks to maintain quality during processing.',
  },
  {
    id: '4',
    name: 'Bunch Separator',
    category: 'Areca-Equipments',
    subcategory: null,
    image: BUNCHSEPERATOR,
    description: 'Efficiently separates areca bunches for easier processing.',
  },
  {
    id: '14',
    name: 'Wood Chipper',
    category: 'Areca-Equipments',
    subcategory: null,
    image: woodchipper,
    description: 'Efficiently chips wood for various agricultural and industrial uses.',
  },
  {
    id: '10',
    name: 'Motocart 125',
    category: 'Areca-Equipments',
    subcategory: null,
    image: MOTOCART125,
    description: 'Compact motorized cart for transporting areca nuts and other materials.',
  },
  {
    id: '13',
    name: 'Motocart 350',
    category: 'Areca-Equipments',
    subcategory: null,
    image: motocart350,
    description: 'High-capacity motorized cart for transporting large quantities of areca nuts.',
  },
  {
    id: '11',
    name: 'Plate Making Machine',
    category: 'Areca-Equipments',
    subcategory: null,
    image: PLATEMAKINGMACHINE,
    description: 'Automates the process of making plates from areca leaves.',
  },
  {
    id: '6',
    name: 'Earth Auger',
    category: 'Areca-Equipments',
    subcategory: null,
    image: EARTHAUGER,
    description: 'Designed for easy and efficient drilling of holes for planting and fencing.',
  },
  {
    id: '7',
    name: 'Gorbal Polisher',
    category: 'Areca-Equipments',
    subcategory: null,
    image: GORBALPOLISHER,
    description: 'Polishes areca nuts for enhanced quality and marketability.',
  },
  {
    id: '3',
    name: 'Areca Tree Climber',
    category: 'Areca-Equipments',
    subcategory: null,
    image: ARECATREECLIMBER,
    description: 'Facilitates the safe and efficient climbing of areca trees for harvesting.',
  },
  {
    id: '8',
    name: 'HTP Sprayer',
    category: 'Areca-Equipments',
    subcategory: null,
    image: HTPSPRAYER,
    description: 'High-pressure sprayer for efficient pesticide and fertilizer application.',
  },
  {
    id: '15',
    name: 'Solar Dryer2',
    category: 'Areca-Equipments',
    subcategory: null,
    image: SolarDryer2,
    description: 'Eco-friendly solar dryer for drying areca nuts and other crops.',
  },
  {
    id: '9',
    name: 'Ladder',
    category: 'Areca-Equipments',
    subcategory: null,
    image: LADDER,
    description: 'Lightweight and durable ladder for easy tree access during harvesting.',
  },
  {
    id: '23',
    name: 'Makita EM3400U',
    category: 'Brushcutter',
    subcategory: null,
    image: makitaem3400ubrushcutter,
    description: 'Engine Displacement : 43CC, Engine Power : 2.1hp, Fuel Type : Petrol, Engine : 2 Stroke, Engine Speed : 9500RPM.  Easy to Operate.  Low Fuel Consumption with increased power.  Walbro Carburetor used.  Can be used for trimming weeds, small trees and other foliage, etc  High Efficient Clutch.',
  },
  {
    id: '18',
    name: 'Jagvar Weed Cutter JR860',
    category: 'Brushcutter',
    subcategory: null,
    image: JAGVARWEEDCUTTERJR860,
    description: 'Engine Type 2 Strokes, Type of Product Crop Cutter, Weight 11.68 Kg, Power 2 HP, Displacement 43 cc, Dimension 87x34.5x25.5 cm.',
  },
  {
    id: '21',
    name: 'Sunkraft GI45',
    category: 'Brushcutter',
    subcategory: null,
    image: SUNKRAFTGI45,
    description: 'Engine Type 2 Strokes, Type of Product Crop Cutter, Weight 11.68 Kg, Power 2 HP, Displacement 43 cc, Dimension 87x34.5x25.5 cm.',
  },
  {
    id: '16',
    name: 'Honda UMK 435',
    category: 'Brushcutter',
    subcategory: null,
    image: HONDAUMK435,
    description: 'Air Cleaner Dry (paper type), Compression ratio 8.0 : 1, Cooling System Forced Air, Fuel Type Unleaded gasolineIgnition System Transistorized Magneto Ignition, Model Honda GX35T, Net Power 1.2kW(1.6hp) @ 7000 rpm, Starting System Recoil Starter, Dry Weight(Kg) 7.5, Dimensions (L x W x H) 1928 x 637 x 428, Engine Honda GX35, Fuel Capacity 0.63 L, Displacement (cm3) 35.8.',
  },
  {
    id: '22',
    name: 'Maijo BC 520B',
    category: 'Brushcutter',
    subcategory: null,
    image: maijoBC520B,
    description: ' Material: Steel  Colour: Multi-Colour;  Powered By: Gasoline-Powered;  Voltage: 250 Volts,  Dimension (L x W x H): 189 cm x 63 cm x 47 cm',
  },
  {
    id: '20',
    name: 'Oleo Mac Sparta 44 N',
    category: 'Brushcutter',
    subcategory: null,
    image: OLEOMACSPARTA44N,
    description: 'Engine Displacement 43 CC, Engine Type 2 Stroke, Brand Oleo Mac, Weight 7.4 KG, Power Source Petrol, Material Heavy Duty, Fuel Tank Capacity 0.85L, Model SPARTA 44N, Power 2 HP.',
  },
  {
    id: '19',
    name: 'KISANKRAFT KK BC 8640',
    category: 'Brushcutter',
    subcategory: null,
    image: KKBC8640,
    description: 'Displacement: 42.7 cc, Speed: 6600 RPM, Weight: 8.6 kg, Engine: 2-Stroke, Air Cooled, Fuel Used: Petrol, Fuel Tank Capacity: 1.1 L, Fuel Consumption: 1 L/hr.',
  },
  {
    id: '17',
    name: 'Husqvarna Brushcutter',
    category: 'Brushcutter',
    subcategory: null,
    image: HUSQVARNABRUSHCUTTER,
    description: 'Cylinder displacement 32.6 cm³, Output shaft power1 0.9 kW, Fuel tank volume 0.9 l, Fuel consumption 550 g/kWh, Maximum power speed 7,000 rpm.',
  },
  {
    id: '25',
    name: 'Makita UR3501',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: MAKITAUR3501,
    description: 'Brand Makita, Power Source Corded Electric, Colour Multi-Colored, Item Weight 4.3 Kilograms, Cutting Width 350 Millimeters, Product Dimensions: 20L x 18.5W Centimeters, Speed 7500 RPM.',
  },
  {
    id: '27',
    name: 'Cub Cadet',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: cubcadet,
    description: 'Brand Cub Cadet, Power (HP) 4.3, Power Type Petrol/Diesel, Size/Dimension 22 inch.',
  },
  {
    id: '24',
    name: 'Kasei',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: KASEI,
    description: 'Lightweight and efficient electric brush cutter suitable for precise trimming.',
  },
  {
    id: '26',
    name: 'Sunkraft Trolley',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: SUNKRAFT,
    description: 'Reliable electric brush cutter for medium-duty trimming and clearing tasks.',
  },
  {
    id: '32',
    name: 'DSC 7626 Tap N Go',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7626,
    description: ' Heavy Duty  Easy to Use  Used for cutting grass and weeds. To be used in a stone region.  This tap and go model is compatible with brush cutters.',
  },
  {
    id: '33',
    name: 'DSC 7644 Tap N Go',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7644,
    description: ' Heavy Duty  Easy to Use  Used for cutting grass and weeds. To be used in a stone region.  This tap and go model is compatible with brush cutters.',
  },
  {
    id: '34',
    name: 'DSC 7648 Tap N Go',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7648,
    description: ' Heavy Duty  Easy to Use  Used for cutting grass and weeds. To be used in a stone region.  This tap and go model is compatible with brush cutters.',
  },
  {
    id: '28',
    name: 'DSC 7661 Blade',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7661copy,
    description: 'Blade is a attachment of Grass / Brush Cutter used to trim Grass & Weeds. Material of blade is heavy duty CARBON STEEL. These blades used for grass cutting, weed cutting and crop cutting and it can be fix in any type of brush cutter.',
  },
  {
    id: '29',
    name: 'DSC 7682 Blade',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7682,
    description: 'Blade is a attachment of Grass / Brush Cutter used to trim Grass & Weeds. Material of blade is heavy duty CARBON STEEL. These blades used for grass cutting, weed cutting and crop cutting and it can be fix in any type of brush cutter.',
  },
  {
    id: '30',
    name: 'DSC 7716 Blade',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7716copy,
    description: 'Blade is a attachment of Grass / Brush Cutter used to trim Grass & Weeds. Material of blade is heavy duty CARBON STEEL. These blades used for grass cutting, weed cutting and crop cutting and it can be fix in any type of brush cutter.',
  },
  {
    id: '31',
    name: 'DSC 7679 Rope',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7679,
    description: ' Product Type : Nylon Rope.  Rope Type : round and square.  Line Diameter : 3 mm.  Length : 1mtr -50 Meter availbale.  Use to cut through grass, weeds and thick brush cleanly and quickly.  Unbreakable, Long Lasting, Weatherproof.',
  },
  {
    id: '35',
    name: 'Belt',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: BELT,
    description: 'Comfortable belt for ease of carrying and using brush cutters.',
  },
  {
    id: '36',
    name: 'Face Guard',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: FACEGUARD,
    description: 'Protective face guard for safe operation during trimming and cutting.',
  },
  {
    id: '37',
    name: 'Glass',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: GLASS,
    description: 'Safety glass for protection while operating brush cutters.',
  },
  {
    id: '40',
    name: 'Makita HW102',
    category: 'Car-Washer',
    subcategory: null,
    image: MAKITHAHW102,
    description: 'Makita HW102 pressure washer for efficient cleaning with high performance.'
  },
  {
    id: '42',
    name: 'Sunkraft SK1',
    category: 'Car-Washer',
    subcategory: null,
    image: sunkraftSK1,
    description: 'Sunkraft SK1, a powerful pressure washer with advanced cleaning technology.'
  },
  {
    id: '39',
    name: 'Kisankraft KKPWIM007',
    category: 'Car-Washer',
    subcategory: null,
    image: KISAKRAFTKKPWIM007,
    description: 'Durable car washer suitable for various cleaning tasks in commercial or domestic use.'
  },
  {
    id: '41',
    name: 'Sunkraft HTP',
    category: 'Car-Washer',
    subcategory: null,
    image: SUNKRAFTHTP,
    description: 'Sunkraft HTP high-pressure washer for efficient and powerful cleaning of vehicles.'
  },
  {
    id: '38',
    name: 'Karcher K2 350 CAP',
    category: 'Car-Washer',
    subcategory: null,
    image: KARCHERK2350CAP,
    description: 'High-pressure car washer, ideal for light to medium-duty cleaning tasks.'
  },
  {
    id: '43',
    name: 'Sunkraft SK170',
    category: 'Car-Washer',
    subcategory: null,
    image: sunkraftSK170,
    description: 'Sunkraft SK170, designed for heavy-duty car cleaning with great durability.'
  },
  {
    id: '48',
    name: 'Sunkraft SKWPP30',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: SUNKRAFTSKWPP30,
    description: 'Sunkraft SKWPP30, a high-performance diesel engine pump designed for robust water pumping operations.'
  },
  {
    id: '44',
    name: 'Honda WB20XD',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: HONDAWB20XD,
    description: 'Honda WB20XD, a reliable diesel engine pump for efficient water pumping in various agricultural and industrial applications.'
  },
  {
    id: '45',
    name: 'Honda WB30XD',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: HONDAWB30XD,
    description: 'Honda WB30XD, a heavy-duty diesel pump for pumping large amounts of water in challenging conditions.'
  },
  {
    id: '46',
    name: 'Kisankraft 3HP KKWP-P21',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: KISANKRAFT3HPkkwpp21,
    description: 'Kisankraft 3HP pump for efficient and durable pumping solutions in farming and irrigation.'
  },
  {
    id: '47',
    name: 'Kisankraft 5HP KK-WPDV-178',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: KISANKRAFT5HPKKWPDV178,
    description: 'Kisankraft 5HP diesel pump, designed for heavy-duty water pumping in agricultural and industrial sectors.'
  },
  
  {
    id: '49',
    name: 'Honda GX35',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: GX35,
    description: 'GX35 is a compact and lightweight engine, ideal for smaller equipment and machinery requiring reliable power.'
  },
  {
    id: '50',
    name: 'Honda GX80',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: GX80,
    description: 'GX80 is a powerful engine designed for heavy-duty use, offering reliability and performance for various machinery.'
  },
  {
    id: '52',
    name: 'Honda GX160',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: gx160,
    description: 'GX160 is a high-performance 5HP engine, known for its reliability in powering heavy-duty pumps and equipment.'
  },
  {
    id: '51',
    name: 'Briggs & Stratton 3HP',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: brigGS3HP,
    description: 'Briggs & Stratton 3HP engine, delivering excellent power and durability for industrial-grade pumping systems.'
  },
  {
    id: '53',
    name: 'ULTIMA 22',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'PUMP',
    image: ULTIMA22,
    description: 'ULTIMA 22 is a high-efficiency pump designed for large-scale water pumping operations in agricultural and industrial settings.'
  },
  {
    id: '54',
    name: 'ULTIMA 30',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'PUMP',
    image: ULTIMA30,
    description: 'ULTIMA 30 pump delivers superior performance and durability for heavy-duty applications, ensuring continuous and reliable pumping.'
  },
  {
    id: '57',
    name: 'Jagvar Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: jagvar,
    description: 'This Chainsaw is a high-performance power tool designed for all your heavy-duty cutting tasks. The chainsaw features a robust 52cc engine delivering powerful and efficient performance. Its 22-inch bar length is ideal for cutting larger logs and trees, offering ample reach and control. This fuel-powered chainsaw has been designed with safety and convenience in mind. reducing wear and tear and prolonging the lifespan of the unit. The handle reduces operator fatigue and provides a comfortable grip during extended use.'
  },
  {
    id: '56',
    name: 'Himax Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: himaxchainsaw,
    description: 'Guide Bar Length	•	55.8 cm, Blade Material	•	Cast Iron, Engine Type	•	Gasoline/Petrol, Displacement	•	58 cc, Motor Speed	•	3300 RPM.'
  },
  {
    id: '58',
    name: 'Stihl Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: stihl,
    description: 'This chainsaw gets back to basics with improved engine air flow and shroud design. With its improved design comes enhanced engine cooling and air filtration, giving you more consistent performance in the field. Make quick work of trimming branches, cutting small trees and clearing fallen limbs after a storm. If you’re looking for a reliable, light-duty chainsaw for occasional use.'
  },
  {
    id: '55',
    name: 'Husqvarna Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: HUSQVARNACHAINSAW,
    description: 'Robust, reliable and easy to use saw with ample cutting performance for homeowners. The perfect garden chainsaw for occasional cutting tasks such as cutting firewood and felling smaller trees. Starts quickly and handles easily, and thanks to the side mounted chain tensioner, tensioning the chain is both quick and easy.'
  },
  {
    id: '61',
    name: 'Oregon Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: OREGON,
    description: '•	POWERFUL PERFORMANCE - 15 Amp electric chainsaw with built-in chain brake. Ideal saw for professionals and homeowners who cut wood in harsh environments. Low vibration, low recoil saw. •	ControlCut Saw Chain - This Oregon chainsaw comes with an included low-maintenance chain, which produces a smooth cut every time and stays sharper longer. •	SMART DESIGN - 16 inch chainsaw with built-in chain tightening system no tools required. Other features include oil level window and front hand guard to protect from flying debris. Quiet between cuts. •	AUTOMATIC GREASER - Automatic oiler distributes oil on the bar and chain while on the saw, keeping your chainsaw lubricated and preventing premature wear. •	ERGONOMIC - Lightweight and balanced, low vibration, over-molded comfort handle. 2-year warranty included. Includes 1 electric chainsaw, 16" guide bar and chain.'
  },
  {
    id: '62',
    name: 'Xlent Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: xlent,
    description: '•	VOLTAGE : 220V •	INPUT POWER  : 2400 W •	NO LOAD SPEED  : 4800 RPM •	MAX CUTTING LENGTH : 405MM, Weight	8.5 kg, Dimensions	56 × 50 × 33 cm.'
  },
  {
    id: '59',
    name: 'CanFly Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: CANFLY,
    description: 'Power Type	Electricity, Guide bar size	16 inch(405mm), Chain	16" chain, Brand Name	CANFLY professional chainsaw, Feature	Anti-Slip, Rate voiltage	220V, output power	1.7kw, saw chain speed	480m/min.'
  },
  {
    id: '60',
    name: 'Mini Stihl Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: MINISTIHL,
    description: 'Type of Product	Cordless Saw, Model No	GTA 26, Weight	1.45 Kg, Battery Type	Li-Ion / AS, Voltage	10.8V.'
  },
  {
    id: '63',
    name: 'Chainsaw Bar',
    category: 'Chainsaw',
    subcategory: 'CHAINSAW-ACCESSORIES',
    image: BAR,
    description: 'ULTBAR18	Ultima Guide Bar 18", ULTBAR180	Ultima Guide Bar 18" MS180, ULTBAR250	Ultima Guide Bar 18" MS250, ULTBAR170	Ultima Guide Bar 16" MS170.'
  },
  {
    id: '65',
    name: 'Chainsaw Chain',
    category: 'Chainsaw',
    subcategory: 'CHAINSAW-ACCESSORIES',
    image: CHAIN,
    description: 'ULT21LPX	Ultima Chain 18", ULTSTRCT	Ultima Straight Cut Chain 18"	, ULTCN180	Ultima Chain 18" MS180, ULTSCC180	Ultima Straigh Cut Chain 18" MS180, ULTCN250	Ultima Chain 18" MS250.'
  },
  {
    id: '64',
    name: 'Chain Filing Machine',
    category: 'Chainsaw',
    subcategory: 'CHAINSAW-ACCESSORIES',
    image: CHAINFILINGMACHINE,
    description: 'Chain Filing Machine, designed to keep your chainsaw chains sharp and in optimal cutting condition with minimal effort.'
  },
  {
    id: '70',
    name: 'Single Cluster Milking Machine',
    category: 'Dairy-Products',
    subcategory: null,
    image: singleclusterMILKINGMACHINE,
    description: 'Single Cluster Milking Machine, a compact solution for milking cows with one cluster, ideal for small-scale operations.'
  },
  {
    id: '66',
    name: 'Double Cluster Milking Machine',
    category: 'Dairy-Products',
    subcategory: null,
    image: DOUBLECLUSTERMILKINGMACHINE,
    description: 'Double Cluster Milking Machine, designed for efficient milking of cows, maximizing productivity and ensuring animal comfort.'
  },
  {
    id: '67',
    name: 'Manual Milking Machine',
    category: 'Dairy-Products',
    subcategory: null,
    image: MANUALMILKINGMACHINE,
    description: 'Manual Milking Machine, a simple and effective solution for small-scale dairy farms with manual operation.'
  },
  {
    id: '68',
    name: 'Cow Mat',
    category: 'Dairy-Products',
    subcategory: null,
    image: cowmat,
    description: 'Cow Mat, designed to provide comfort and prevent injuries for cows during milking and resting.'
  },
  {
    id: '69',
    name: 'Curd Churner',
    category: 'Dairy-Products',
    subcategory: null,
    image: curdchurner,
    description: 'Curd Churner, used to churn milk and make curd in an efficient and hygienic manner for dairy products.'
  },
  {
    id: '71',
    name: 'Bhoomi Chaffcutter',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: BHOOMI,
    description: 'Bhoomi Chaffcutter, designed to cut dry fodder for livestock, enhancing feed efficiency for dairy and agricultural farms.'
  },
  {
    id: '72',
    name: 'Easy Mini Chaffcutter',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: EASYMINI,
    description: 'Easy Mini Chaffcutter, a compact and efficient solution for small-scale farms, ideal for chopping fodder for animals.'
  },
  {
    id: '73',
    name: 'KK FMC 500 with 3hp Motor',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: KKFMC500WITH3hpMotor,
    description: 'KK FMC 500 with 3hp Motor, a powerful chaffcutter designed for medium-scale farms to efficiently cut fodder for animals.'
  },
  {
    id: '74',
    name: 'Manual Milking Machine',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: ManualMilkingMachine,
    description: 'Manual Milking Machine, a simple and efficient solution for small farms to manually milk cows with ease.'
  },
  {
    id: '77',
    name: 'Makita HW102',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: MAKITHAHW1021,
    description: 'Makita HW102, a reliable and compact pressure washer that provides efficient cleaning power for dairy farm equipment.'
  },
  {
    id: '78',
    name: 'Sunkraft SK1',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: sunkraftSK11,
    description: 'Sunkraft SK1, an industrial-grade pressure washer built for heavy-duty cleaning of large surfaces and machinery in the dairy industry.'
  },
  {
    id: '76',
    name: 'Kisankraft KKPWIM007',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: KISAKRAFTKKPWIM0071,
    description: 'Kisankraft KKPWIM007, a high-pressure washer designed for various cleaning tasks in agricultural and dairy farm operations.'
  },
  {
    id: '75',
    name: 'Karcher K2 350 CAP',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: KARCHERK2350CAP1,
    description: 'Karcher K2 350 CAP, a versatile pressure washer designed for cleaning farm equipment, vehicles, and outdoor areas efficiently.'
  },
  {
    id: '79',
    name: 'Sunkraft SK170',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: sunkraftSK1701,
    description: 'Sunkraft SK170, a high-efficiency pressure washer designed for cleaning dairy farms, vehicles, and equipment with ease.'
  },
  {
    id: '85',
    name: 'Lawnmower',
    category: 'Garden-Equipments',
    subcategory: null,
    image: LAWNMOWER,
    description: 'Lawnmower designed for easy and effective grass cutting, ensuring a neat and even lawn.'
  },
  {
    id: '92',
    name: 'Stubble Mower',
    category: 'Garden-Equipments',
    subcategory: null,
    image: STUBBLEMOWER,
    description: 'Stubble Mower for cutting and clearing stubble and vegetation in agricultural and garden fields.'
  },
  {
    id: '94',
    name: 'Cub Cadet',
    category: 'Garden-Equipments',
    subcategory: null,
    image: cubcadet1,
    description: 'Cub Cadet garden equipment designed for efficient garden work, offering powerful and reliable performance.'
  },
  {
    id: '87',
    name: 'Manual Sprayer',
    category: 'Garden-Equipments',
    subcategory: null,
    image: MANUALsprayerS,
    description: 'Manual sprayer for controlled and effective spraying of pesticides, herbicides, or fertilizers in gardens.'
  },
  {
    id: '96',
    name: 'Fruit Picker',
    category: 'Garden-Equipments',
    subcategory: null,
    image: fruitpicker,
    description: 'Fruit Picker tool designed to safely and efficiently harvest fruits from tall trees.'
  },
  {
    id: '81',
    name: 'Electric Hedge Trimmer',
    category: 'Garden-Equipments',
    subcategory: null,
    image: ELECTRICHEDGETRIMMER,
    description: 'Electric Hedge Trimmer for quick and efficient trimming of garden hedges and shrubs with minimal effort.'
  },
  {
    id: '88',
    name: 'Root Puller',
    category: 'Garden-Equipments',
    subcategory: null,
    image: ROOTPULLER,
    description: 'Root Puller tool for easily removing weeds and unwanted plants from garden soil, especially in hard-to-reach areas.'
  },
  {
    id: '80',
    name: 'Axe',
    category: 'Garden-Equipments',
    subcategory: null,
    image: AXE,
    description: 'Axe for effective tree cutting and heavy-duty gardening tasks, designed for durability and ease of use.'
  },
  {
    id: '84',
    name: 'Hedge Shear',
    category: 'Garden-Equipments',
    subcategory: null,
    image: HEDGESHEAR,
    description: 'Hedge Shear for easy cutting of thick hedges and shrubs, built for comfort and efficiency.'
  },
  {
    id: '98',
    name: 'Snake Catcher',
    category: 'Garden-Equipments',
    subcategory: null,
    image: snakecatcher,
    description: 'Snake Catcher tool for safely handling and relocating snakes without harm.'
  },
  {
    id: '90',
    name: 'Shade Net',
    category: 'Garden-Equipments',
    subcategory: null,
    image: SHADENET,
    description: 'Shade Net used for protecting plants from excess sunlight, promoting healthy growth in gardens.'
  },
  {
    id: '99',
    name: 'Grow Bag',
    category: 'Garden-Equipments',
    subcategory: null,
    image: GROWBAG,
    description: 'Grow Bag for cultivating plants in small spaces, providing excellent drainage and root growth.'
  },
  {
    id: '89',
    name: 'Rose Can',
    category: 'Garden-Equipments',
    subcategory: null,
    image: ROSECAN,
    description: 'Rose Can designed for watering delicate plants like roses, ensuring controlled water distribution.'
  },
  {
    id: '82',
    name: 'Falcon',
    category: 'Garden-Equipments',
    subcategory: null,
    image: FALCON,
    description: 'Falcon garden tool for efficient and precise cutting and pruning in your garden.'
  },
  {
    id: '97',
    name: 'Hectare Products',
    category: 'Garden-Equipments',
    subcategory: null,
    image: hectareproducts,
    description: 'Hectare Products for large-scale agricultural and gardening operations, offering a variety of useful tools.'
  },
  {
    id: '93',
    name: 'Weedmat',
    category: 'Garden-Equipments',
    subcategory: null,
    image: Weedmat,
    description: 'Weedmat designed to suppress weed growth in gardens while allowing water and nutrients to pass through.'
  },
  {
    id: '86',
    name: 'Manual Weeder',
    category: 'Garden-Equipments',
    subcategory: null,
    image: MANUALWEEDER,
    description: 'Manual Weeder for removing unwanted weeds efficiently from your garden beds with minimal effort.'
  },
  {
    id: '95',
    name: 'Easycut',
    category: 'Garden-Equipments',
    subcategory: null,
    image: easycut,
    description: 'Easycut garden tool for cutting grass and small plants efficiently with minimal effort.'
  },
  {
    id: '83',
    name: 'Gardening Tools',
    category: 'Garden-Equipments',
    subcategory: null,
    image: GARDENINGTOOLS,
    description: 'A complete set of gardening tools designed to assist in all your gardening activities.'
  },
  {
    id: '91',
    name: 'Sprayer',
    category: 'Garden-Equipments',
    subcategory: null,
    image: SPRAYER,
    description: 'Sprayer ideal for spraying water, fertilizers, or pest control solutions in large gardens or small farms.'
  },
  {
    id: '100',
    name: 'Honey Bee Hive Jungle Wood Box',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HaneyEquipHoneyBeeHiveJunglewoodBox01,
    description: 'Honey Bee Hive Jungle Wood Box, designed for optimal bee colony management and honey harvesting.'
  },
  {
    id: '105',
    name: 'Honey Bee Hive Box 02',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipHoneyBeeHive02,
    description: 'Honey Bee Hive 02, designed for efficient management and honey production with easy access for beekeepers.'
  },
  {
    id: '101',
    name: 'Honey Nano Bee Hive Box',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HaneyEquipHoneyNanoBeeHiveBox01,
    description: 'Honey Nano Bee Hive Box, a compact and efficient hive box for managing bee colonies in small spaces.'
  },
  {
    id: '108',
    name: 'Queen Gate Plastic',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipQueenGate03,
    description: 'Queen Gate 03, designed for safe and efficient management of queen bees in hive boxes.'
  },
  {
    id: '107',
    name: 'Queen Gate',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipQueenGate02,
    description: 'Queen Gate 02, used to control the movement of the queen bee in the hive for better management of bee colonies.'
  },
  {
    id: '106',
    name: 'Iron Stand',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipProductsIronStand01,
    description: 'Iron Stand for Honey Equip Products, ensuring stability and durability while managing beekeeping equipment.'
  },
  {
    id: '104',
    name: 'Bee Veil',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipBeeVeil01,
    description: 'Bee Veil designed to protect beekeepers from bee stings during hive management and inspections.'
  },
  {
    id: '103',
    name: 'Bee Smoker',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipBeeSmoker01,
    description: 'Bee Smoker for calming bees during hive inspections, ensuring a safer and smoother honey harvesting process.'
  },
  {
    id: '102',
    name: 'Honey Extractor Galvanized Iron Sheet',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HaneyEquipHoneyextractorsGalvanizedIronSheet,
    description: 'Honey Extractor made from galvanized iron sheet, ideal for extracting honey in a hygienic and efficient manner.'
  },
  {
    id: '109',
    name: 'Cute Honey Bee Stickers',
    category: 'Honey-Equipments',
    subcategory: null,
    image: cutehoneybeestickersforcaroranywherestickerbumperstickeralibees782517_grande,
    description: 'Cute Honey Bee Stickers for cars or any surface, a fun way to show your love for bees and honey!'
  },
  {
    id: '116',
    name: 'Finolex Pipes',
    category: 'Irrigation',
    subcategory: null,
    image: finolexpipes,
    description: 'Finolex Pipes used in irrigation systems to transport water effectively across long distances in agricultural fields.'
  },
  {
    id: '118',
    name: 'Sprinkler',
    category: 'Irrigation',
    subcategory: null,
    image: sprinkler,
    description: 'Sprinkler for even water distribution in agricultural fields, helping with efficient irrigation and crop growth.'
  },
  {
    id: '115',
    name: 'Drip Irrigation Items',
    category: 'Irrigation',
    subcategory: null,
    image: dripitems,
    description: 'Drip Irrigation Items designed for efficient water distribution directly to plant roots, promoting water conservation and better yields.'
  },
  {
    id: '114',
    name: 'Water Tank',
    category: 'Irrigation',
    subcategory: null,
    image: WATERTANK,
    description: 'Water Tank for storing water for irrigation and other farm-related needs, available in various sizes and capacities.'
  },
  {
    id: '112',
    name: 'Submersible Pump',
    category: 'Irrigation',
    subcategory: null,
    image: SUBMERSIBLEPUMP,
    description: 'Submersible Pump designed for pumping water from wells, tanks, or boreholes, perfect for irrigation and drainage.'
  },
  {
    id: '110',
    name: 'Monobloc Pump',
    category: 'Irrigation',
    subcategory: null,
    image: MONOBLOCPUMP,
    description: 'Monobloc Pump, designed for efficient water pumping in agricultural irrigation systems and water distribution.'
  },
  {
    id: '111',
    name: 'Slurry Pump',
    category: 'Irrigation',
    subcategory: null,
    image: SLURRYPUMP,
    description: 'Slurry Pump for handling and transferring slurry and other thick fluids in agricultural and industrial applications.'
  },
  {
    id: '117',
    name: 'Multistage Pump',
    category: 'Irrigation',
    subcategory: null,
    image: multistagepump,
    description: 'Multistage Pump for high-pressure water lifting applications, ideal for large-scale irrigation and water supply systems.'
  },
  {
    id: '113',
    name: 'Water Pump',
    category: 'Irrigation',
    subcategory: null,
    image: WATERPUMP,
    description: 'Water Pump used for transferring water to various locations, ideal for irrigation systems and general water management.'
  },
  {
    id: '129',
    name: 'Easycut',
    category: 'Other-Equipments',
    subcategory: null,
    image: easycut1,
    description: 'Easycut tool for precision cutting in gardens and fields, ideal for small-scale farming operations.'
  },
  {
    id: '120',
    name: 'Generator',
    category: 'Other-Equipments',
    subcategory: null,
    image: GENERATOR,
    description: 'Generator for providing backup power to agricultural equipment and operations during power outages.'
  },
  {
    id: '123',
    name: 'Stubble Mower',
    category: 'Other-Equipments',
    subcategory: null,
    image: STUBBLEMOWER1,
    description: 'Stubble Mower for clearing stubble and other vegetation from the fields after harvest, improving soil conditions for the next planting.'
  },
  {
    id: '125',
    name: 'Blower',
    category: 'Other-Equipments',
    subcategory: null,
    image: blower,
    description: 'Blower for clearing debris from fields, gardens, and paths efficiently.'
  },
  {
    id: '132',
    name: 'Mist Blower',
    category: 'Other-Equipments',
    subcategory: null,
    image: mistblower,
    description: 'Mist Blower for spraying liquid products over crops and plants, ideal for pest control and fertilization.'
  },
  {
    id: '122',
    name: 'MS-5000 Mini Gas Fogger',
    category: 'Other-Equipments',
    subcategory: null,
    image: MS5000MiniGasFogger,
    description: 'MS-5000 Mini Gas Fogger for pesticide spraying in agricultural fields, designed for precision and ease of use.'
  },
  {
    id: '130',
    name: 'Fogging Machine',
    category: 'Other-Equipments',
    subcategory: null,
    image: foggingmachine,
    description: 'Fogging Machine for effective spraying of pesticides, insecticides, and other chemicals in agricultural fields.'
  },
  {
    id: '131',
    name: 'Karcher Vacuum Cleaner',
    category: 'Other-Equipments',
    subcategory: null,
    image: karchervaccumcleaner,
    description: 'Karcher Vacuum Cleaner for efficient cleaning of surfaces in agricultural and industrial environments.'
  },
  {
    id: '119',
    name: 'Coconut Husk Peeler',
    category: 'Other-Equipments',
    subcategory: null,
    image: COCONUTHUSKPEELER,
    description: 'Coconut Husk Peeler for efficiently peeling coconuts, making it easier for processing in agriculture and industry.'
  },
  
  {
    id: '121',
    name: 'Ladder',
    category: 'Other-Equipments',
    subcategory: null,
    image: LADDER1,
    description: 'Ladder designed for safe access to high places, useful in farming, gardening, and other outdoor tasks.'
  },
  
  
  {
    id: '124',
    name: 'Weedmat',
    category: 'Other-Equipments',
    subcategory: null,
    image: Weedmat1,
    description: 'Weedmat for preventing weed growth in agricultural fields and gardens while allowing water and air circulation.'
  },
 
  {
    id: '126',
    name: 'Boiler',
    category: 'Other-Equipments',
    subcategory: null,
    image: boiler,
    description: 'Boiler designed for industrial and agricultural use, providing steam for heating and other operations.'
  },
  {
    id: '127',
    name: 'Coconut Climber',
    category: 'Other-Equipments',
    subcategory: null,
    image: coconutclimber,
    description: 'Coconut Climber for safely and efficiently harvesting coconuts from tall trees.'
  },
  {
    id: '128',
    name: 'Cylinder Trolley',
    category: 'Other-Equipments',
    subcategory: null,
    image: cylindertrolley,
    description: 'Cylinder Trolley designed for easy transportation of gas cylinders in agricultural and industrial settings.'
  },
 
  
  
  {
    id: '133',
    name: 'Rain Cover',
    category: 'Other-Equipments',
    subcategory: null,
    image: raincover,
    description: 'Rain Cover for protecting agricultural equipment, plants, and machinery from rain and other weather conditions.'
  },
  {
    id: '134',
    name: 'Shade Net',
    category: 'Other-Equipments',
    subcategory: null,
    image: shadenet,
    description: 'Shade Net for providing protection against excessive sunlight, ensuring healthier plant growth in greenhouses and farms.'
  },
  {
    id: '135',
    name: 'Solar Trap',
    category: 'Other-Equipments',
    subcategory: null,
    image: solartrap,
    description: 'Solar Trap for capturing solar energy for agricultural use, providing a sustainable source of power for various farm operations.'
  },
  {
    id: '141',
    name: 'Power Tiller',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: powertiller,
    description: 'Engine : 13HP -2400 RPM, Engine Type : 4 stroke, direct injection/ water cooled, Fuel Consumption : 190 gms/hp/hour, Fuel : Diesel, Fuel Tank Capacity : 11 litres, DriveSide driveGear : 6 Forward & 2 Reverse, No Of Blades : 18.',
  },
  {
    id: '143',
    name: 'Tractor 20HP-60HP',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: tractor20hp60hp,
    description: "Sonalika Tractors is a leading tractor brand known for being India's No.1 export brand. It offers a wide range of heavy-duty tractors. Sonalika tractor HP ranges from 20 to 120 HP. These tractors, available in 2WD and 4WD, are designed for various farming tasks, such as pulling heavy loads, puddling, and ploughing. Additionally, this brand has entered the electric world with Sonalika Tiger Electric, India’s first field-ready electric tractor."
  },
  {
    id: '140',
    name: 'Rotary Tiller',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: ROTARYTILLER,
    description: ' Engine : 5HP-7.3 HP, Engine Capacity : 296, Engine Type : 4 Stroke, Fuel : Diesel, Fuel Tank Capacity : 3.0 L-5.0 litres'
  },
  {
    id: '144',
    name: 'Transplanter',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: transplanter,
    description: 'MP461 4 Row Rice Transplanter, Brand	Mahindra, Engine Type	Air cooled, single cylinder, 4 stroke, Fuel Tank Capacity	3.5 L, Weight	180 Kg (Operating), Engine Model	MF168 FB, Displacement Cc	196 cm3, Type	4 rows, Model Number	MP461, Rated Output	3.72 kW and 1700 r/min, Fuel	Petrol, Number. of Wheels	2, Wheel Type	Rubber lug wheel'
  },
  {
    id: '142',
    name: 'Reaper',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: reaper,
    description: 'MAIJO REPAER 7 PR, Engine Fuel Type	Diesel, Cutter Bar Width	5 Feet, Capacity	0.5 acres/hr, Model Name / Number	7 PR, Power Type	7 HP, No. Of Blades	4 Blades'
  },
  {
    id: '136',
    name: 'Conoweeder',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: CONOWEEDER,
    description: 'CONOWEEDER, Colour Orange, Material	Mild Steel, Working Width	140-160 mm, Additional Details	Handle Size: 25x430 mm, Dimensions	320x120x65 mm, Weight	4.50-5 kg'
  },
  {
    id: '138',
    name: 'Manual Seeder',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: MANUALSEEDER,
    description: 'MANUAL SEEDER has reduced the need for transplantation and hours of human labor, which would literally break the backs of farmers involved in sowing paddy seeds in the field. With a single operator effort, it can cover 8 rows of 20 cm row-to-row spacing at once. •	 Product Type: Manual Seeder, •	Material : Plastic, •	Automation : Manual'
  },
  {
    id: '137',
    name: 'Drum Seeder',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: DRUMSEEDER,
    description: 'Drum Seeder for uniform sowing of seeds in paddy fields, designed for high productivity and minimal labor.'
  },
  {
    id: '139',
    name: 'Nursery Tray',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: NURSERYTRAY,
    description: 'Nursery Tray for growing seedlings, designed to improve the efficiency and health of paddy plant growth.•	Helps in saving water and fertilizers, •	Seedlings are planted in an organized manner helping with weed management, •	This method provides 25% more yield than the conventional method, •	50% of prime cost is saved compared to the conventional method, •	More land area can be covered, •	Minimum labor is required, •	Reusable for several cycles, •	High durability and strength, •	Material : Plastic, •	Shape : Rectangular, •	Size (L x W x H) : 24 x 12 x 1 Inches (60.96 x 30.48 x 2.54 cm), •	Colour : Black'
  },
  {
    id: '145',
    name: 'Baler',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: BALER,
    description: 'A baler is a farm machine that compresses cut and raked crops into bales that are easier to handle, transport, and store. Balers are used to compact crops such as hay, cotton, flax straw, salt marsh hay, or silage. MODEL	JUMBO 1209, Bale length	93 cm, Bale diameter	60 cm, Bale weight	28-32 kg, No.of rollers	12, Features •	Saves Fuel & Time, •	Low Maintenace Cost, •	Suitable for wet and dry land, •	Robust & Sturdy structure, •	Reduces Load on tractors, •	Maintains Moisture Level of Soil, •	Ideal  for Crops like Cotton, wheat, Ground Nut, Sugarcane, Rice, Vegetables, etc.'
  },
  {
    id: '153',
    name: 'Rotovator',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: rotovator,
    description: 'The ROTOVATOR  is a medium and hard soil rotary tiller with combined features or its predecessors Light and Smart. Available in multiple drives, sppeds and blades options ; it can produce superior seedbeds for both dry and wet cultivation. Speed	Regular Multi Speed, Overall Width (Feet)	4feet to 6 Feet, Number of Blades	24-42, Model Name/Number	Heavy Duty, Brand	Rotoking, Blade Type	L Type, Tractor Power hp	30-70 HP'
  },
  {
    id: '151',
    name: 'Spring Cultivator',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: SPRINGCULTIVATOR,
    description: 'Features: 	Secondary tillage implement for aerating & loosing of soil, 	Better pulverization for smooth seed bed preparation, 	Best suitable for use in stoney & rooty soil conditions, 	Spring provides the damping effect like if one tine is obstructed, the other continues to work at proper depth, 	Heavy duty springs with 10 mm WD for over coming obstruction like stones/hooks etc.'
  },
  {
    id: '147',
    name: 'Duckfoot Cultivator',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: DUCKFOOTCULTIVATOR,
    description: ' Type	Duck Foot, No Of Tynes	5-9, Material	iron, Application	Agriculture, Power Required	30HP-55HP, Duck Foot Cultivator: The duck foot cultivator manipulates the soil at shallow to medium depth to prepare seedbed for sowing. It consists of a main frame, three point hitch system and shank to which the sweeps (soil working blades) are attached'
  },
  {
    id: '148',
    name: 'Mobile Shredder',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: MOBILESHREDDER,
    description: "•	It's specially design to tackle different type of crops and field conditions with longer life, •	There are mainly two systems, Feeding & Shredding, •	Removes root while chopping of stalk, Brand	Rotoking, Material	Mild Steel, Gear Box	Multi Speed, Weight	605 cm, Height	1415 cm, Breadth	842 cm, Length	1700 cm, PTO Speed	1000 RPM, Tractor HP	55 HP, No Of Disc	2, No Of Blade	Fly Wheel Blade"
  },
  {
    id: '149',
    name: 'Mulcher',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: MULCHER,
    description: 'Brand	Rotoking Usage/Application	Agriculture & Farming, Model Name/Number	Rrm 180, Material	Mild Steel, Gear Box	Single, Weight	565 Kg, Height	100 cm, Breadth	110 cm, Length	200 cm, PTO Speed	540 RPM, Tractor Power	55 HP, No Of Blade	Y-36, Implement Power	45 HP'
  },
  {
    id: '152',
    name: 'Sugarcane Stubble Shaver',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: SUGARCANESTUBBLESHAVER,
    description: 'Material	Iron, Power	35, Application	For sugarcane cultivation, Brand	Rotoking, Suitable For	Extremely suitable for a tractor of 35 HP to 60 HP, Features Of Rotoking Sugarcane Stubble Shaver: •	Tractor & PTO operated disc type stubbly shaver available with a depth wheel to control the depth of operation. •	Having unique cutting mechanism.'
  },
  {
    id: '150',
    name: 'Post Hole Digger',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: POSTHOLEDIGGER,
    description: 'Material	Mild Steel, Type of Hole Diggers	Farm Equipment, Brand	Rotoking, Condition	New, Application	For Drill/Dig holes of various sizes and depths in soil, Features Of Rotoking Post Hole Digger: •	Our product is easy to operate & suitable for all types of soil. •	Our digger can successfully dig the hole of 9, 12, 18, 24, 30 and 36 inches in less than a minute. •	The digging is faster and less garbage of soil is generated and this soil will be used around the pit and pit can be filled easily.'
  },
  {
    id: '146',
    name: 'Dozer Backhoe & Loader',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: DOZERBACKHOELOADER,
    description: 'Dozer Backhoe & Loader for excavation and earth-moving tasks, ideal for paddy field operations and land leveling.'
  },
  {
    id: '154',
    name: '0.5 HP Green Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: GREENPEPPERPEELINGMACHINE05HP,
    description: '0.5 HP Green Pepper Peeling Machine for efficient peeling of green peppers, designed for small-scale operations.'
  },
  {
    id: '1541',
    name: '0.5 HP Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: PEPPERPEELINGMACHINE05hp,
    description: '0.5 HP Pepper Peeling Machine for efficient peeling of peppers, designed for small-scale operations.'
  },
  {
    id: '156',
    name: '1 HP Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: PEPPERPEELINGMACHINE1HP,
    description: '1 HP Pepper Peeling Machine designed for consistent and high-quality pepper peeling in agricultural processing.'
  },
  {
    id: '1542',
    name: 'Bunch Separator',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: BUNCHSEPERATOR1,
    description: 'Efficiently separates areca bunches for easier processing.',
  },
  {
    id: '155',
    name: '1 HP CAT 960 Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: CAT960PEPPERPEELINGMACHINE1HP,
    description: '1 HP CAT 960 Pepper Peeling Machine for medium-scale pepper peeling operations, ensuring high efficiency.'
  },
  {
    id: '157',
    name: '2HP CAT 970 Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: CAT9702HP,
    description: '2HP CAT 970 Pepper Peeling Machine for large-scale pepper peeling, providing high performance and durability.'
  },
  {
    id: '164',
    name: 'Drill M0600B',
    category: 'Power-Tools',
    subcategory: null,
    image: drillM0600B,
    description: 'Drill M0600B for versatile drilling tasks in wood, metal, and other materials with ease and precision.'
  },
  {
    id: '160',
    name: 'Hammer Drill',
    category: 'Power-Tools',
    subcategory: null,
    image: HAMMERDRILL,
    description: 'Hammer Drill for drilling in tough materials like concrete, masonry, and stone, with high impact force.'
  },
  {
    id: '158',
    name: 'Angle Grinder',
    category: 'Power-Tools',
    subcategory: null,
    image: ANGLEGRINDER,
    description: 'Angle Grinder for cutting, grinding, and polishing various materials like metal, stone, and concrete.'
  },
  {
    id: '161',
    name: 'Impact Driver',
    category: 'Power-Tools',
    subcategory: null,
    image: IMPACTDRIVER,
    description: 'Impact Driver for driving screws and bolts with high torque, ideal for heavy-duty fastening tasks.'
  },
  {
    id: '163',
    name: 'Chain Filing Tool',
    category: 'Power-Tools',
    subcategory: null,
    image: chainfiling,
    description: 'Chain Filing Tool for sharpening chainsaw chains, ensuring optimal cutting performance.'
  },
  {
    id: '166',
    name: 'Vacuum Cleaner',
    category: 'Power-Tools',
    subcategory: null,
    image: vaccumcleaner,
    description: 'Vacuum Cleaner for efficient cleaning of dust, dirt, and debris in homes, workshops, and offices.'
  },
  {
    id: '159',
    name: 'Electric Handheld Pruner',
    category: 'Power-Tools',
    subcategory: null,
    image: ELECTRICHANDHELDPRUNER,
    description: 'Electric Handheld Pruner for effortless pruning of plants and trees, reducing the physical strain of manual cutting.'
  },
  {
    id: '162',
    name: 'Makita Electric Brushcutter',
    category: 'Power-Tools',
    subcategory: null,
    image: MAKITAELECTRICBRUSHCUTTER,
    description: 'Makita Electric Brushcutter for efficient trimming and cutting of grass, weeds, and shrubs.'
  },
  {
    id: '165',
    name: 'Makita Cordless Chainsaw',
    category: 'Power-Tools',
    subcategory: null,
    image: makitaCORDLESSCHAINSAW,
    description: 'Makita Cordless Chainsaw for easy and portable cutting, designed for small to medium-sized jobs.'
  },
  {
    id: '176',
    name: 'Sunkraft SKPSP22',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFT_SKPSP22,
    description: 'Sunkraft SKPSP22 offering high pressure and consistent spray for large farming operations.'
  },
  {
    id: '173',
    name: 'Sunkraft SKPSP30',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFTSKPSP30,
    description: 'Sunkraft SKPSP30 designed for large-scale spraying with a powerful engine for high performance.'
  },
  {
    id: '175',
    name: 'Sunkraft SKPS18',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFT_SKPS18,
    description: 'Sunkraft SKPS18 providing high reliability and precision for spraying chemicals in agricultural applications.'
  },
  {
    id: '172',
    name: 'Sunkraft SK768',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFTSK768,
    description: 'Sunkraft SK768 for consistent and reliable spraying in agricultural and horticultural fields.'
  },
  {
    id: '174',
    name: 'Sunkraft SK868',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFT_SK868,
    description: 'Sunkraft SK868 for efficient pesticide and fertilizer application in various crop fields.'
  },
  {
    id: '178',
    name: 'Ultima Power Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: ULTIMAPOWERSPRAYER,
    description: 'Ultima Power Sprayer for large-scale operations, offering high performance and reliability for farmers.'
  },
  {
    id: '170',
    name: 'Kisankraft KKPS22',
    category: 'Sprayers',
    subcategory: null,
    image: KISANKRAFTKKPS22,
    description: 'Kisankraft KKPS22 for precision spraying in farming, offering excellent durability and performance.'
  },
  {
    id: '171',
    name: 'Kisankraft KKPS30',
    category: 'Sprayers',
    subcategory: null,
    image: KISANKRAFTKKPS30,
    description: 'Kisankraft KKPS30 designed for large field applications, providing effective spraying and high capacity.'
  },
  {
    id: '169',
    name: 'GX80+SK22 Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: GX80SK22,
    description: 'GX80+SK22 Sprayer offering high performance for agricultural spraying, with a focus on fuel efficiency.'
  },
  {
    id: '167',
    name: '168F HTP Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: HTPSPRAYER168F,
    description: '168F HTP Sprayer for efficient spraying of pesticides and fertilizers in large-scale farming operations.'
  },
  {
    id: '168',
    name: 'Gator Rocking Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: GATORROCKINGSPRAYER,
    description: 'Gator Rocking Sprayer designed for versatile applications, ensuring even and thorough coverage of crops.'
  },
  {
    id: '177',
    name: 'Ultima 555',
    category: 'Sprayers',
    subcategory: null,
    image: ULTIMA555,
    description: 'Ultima 555 sprayer for effective application of water, pesticides, and fertilizers on crops.'
  },
  
  {
    id: '179',
    name: 'Ultima 3HP HTP Sprayer UL22',
    category: 'Sprayers',
    subcategory: null,
    image: Ultima3HPHTPSprayerUL22,
    description: 'Ultima 3HP HTP Sprayer UL22 designed for high efficiency and effective spraying in agricultural fields.'
  },
  {
    id: '180',
    name: 'Eco Single Wheel',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: EcoSinglewheel,
    description: 'Eco Single Wheel Barrow designed for easy maneuverability and heavy-duty carrying capacity in agricultural settings.'
  },
  {
    id: '181',
    name: 'Eco Two Wheel WLB2TR',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: EcoTwoWheelWLB2TR,
    description: 'Eco Two Wheel WLB2TR for increased stability and larger carrying capacity, suitable for farming and construction work.'
  },
  {
    id: '182',
    name: 'MTNWB3 Mathana 3 Wheel Barrow',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: MTNWB3Mathana3Wheelbarrow,
    description: 'MTNWB3 Mathana 3 Wheel Barrow with enhanced design for better load distribution and efficiency in fieldwork.'
  },
  {
    id: '183',
    name: 'Push Cart Three Wheel With Breakup PCO3WWB',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: PushcartThreeWheelWithBreakupPCO3WWB,
    description: 'Push Cart Three Wheel With Breakup PCO3WWB designed for easy transport of materials with additional safety features.'
  }
];

export default products;