import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.scss';
import Uparrow from "../../assets/icons/up.png";
import Whatsapp from "../../assets/icons/whatsapp-float.png";

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return isVisible ? (
    <div className="floating-buttons">
          <div className="scroll-to-top" onClick={scrollToTop}>
          <img src={Uparrow}></img>
          </div>
          <a
            href="https://wa.me/message/MYSUG2OU4EFBN1" 
            className="whatsapp-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Whatsapp} alt="" />
          </a>
        </div>
  ) : null;
};

export default ScrollToTopButton;
