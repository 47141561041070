import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import './Header.scss';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-icon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp-icon.svg';
import Logo from "../../assets/images/EASYLIFE LOGO.png";
import Tagline from "../../assets/images/tagline easy.jpg";

const Header: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showSocial, setShowSocial] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
      if (window.scrollY > lastScrollY) {
        setShowSocial(false);
      } else {
        setShowSocial(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {showSocial && (
        <div className="social-container">
          <div className="contact-info">
            <a href="tel:+91 9901876682" className="phone">Call us: +91 990 187 6682</a>
          </div>
          <div className="social-icons">
            <a href="https://wa.me/message/MYSUG2OU4EFBN1" target="_blank" rel="noopener noreferrer">
              <WhatsappIcon className="social-icon" />
            </a>
            <a href="https://www.instagram.com/easylifeenterprises/profilecard/?igsh=MTh1MnE1aG95N2E5cA==" target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="social-icon" />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="social-icon" />
            </a>
            <a href="https://youtube.com/@easylifeenterprises2536?si=0ebLPqbNxjVMmO_q" target="_blank" rel="noopener noreferrer">
              <YoutubeIcon className="social-icon" />
            </a>
          </div>
        </div>
      )}

      <header className={`header-container ${scrolled ? 'scrolled' : ''}`}>
        <div className="right">
          <div className="logo">
            <a href="/">
              <img src={Logo} alt="Easylife logo" />
            </a>
          </div>
          <div className="logo">
            <a href="/">
              <img src={Tagline} alt="Easylife Tagline" />
            </a>
          </div>
        </div>

        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        </div>

        <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
  <NavLink 
    to="/" 
    className={({ isActive }) => (isActive ? 'active' : '')}
    onClick={() => setMenuOpen(false)}
  >
    Home
  </NavLink>
  <NavLink 
    to="/about" 
    className={({ isActive }) => (isActive ? 'active' : '')}
    onClick={() => setMenuOpen(false)}
  >
    About
  </NavLink>
  <NavLink 
    to="/branches" 
    className={({ isActive }) => (isActive ? 'active' : '')}
    onClick={() => setMenuOpen(false)}
  >
    Branches
  </NavLink>
  <NavLink 
    to="/products" 
    className={({ isActive }) => (isActive ? 'active' : '')}
    onClick={() => setMenuOpen(false)}
  >
    Products
  </NavLink>
  <NavLink 
    to="/services" 
    className={({ isActive }) => (isActive ? 'active' : '')}
    onClick={() => setMenuOpen(false)}
  >
    Services
  </NavLink>
  <NavLink 
    to="/contact" 
    className={({ isActive }) => (isActive ? 'active' : '')}
    onClick={() => setMenuOpen(false)}
  >
    ContactUs
  </NavLink>
</nav>
      </header>
    </div>
  );
};

export default Header;
