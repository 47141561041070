import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ScrollToTopButton from './components/SmallComponents/ScrollToTopButton';
import products from './data/products';
import { brandDetails } from './data/brandproducts';
import './App.css';

// Lazy load components for better performance
const Home = lazy(() => import('./components/Home/Home'));
const About = lazy(() => import('./components/About/About'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const Services = lazy(() => import('./components/Services/Services'));
const Branches = lazy(() => import('./components/Branches/Branches'));
const BranchDetails = lazy(() => import('./components/Branches/BranchDetails'));
const ProductPage = lazy(() => import('./components/Products/Products'));
const ProductDetails = lazy(() => import('./components/Products/ProductDetails'));
const BrandPage = lazy(() => import('./components/Products/Brands/BrandPage'));
const BrandProductDetails = lazy(() => import('./components/Products/Brands/BrandProductDetails'));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const simulateLoading = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    simulateLoading();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      <div className="app">
        <Header />
        <Suspense fallback={<Loader />}>
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/branches" element={<Branches />} />
              <Route path="/branches/:district" element={<BranchDetails />} />
              <Route path="/products" element={<ProductPage />} />
              <Route path="/products/:category" element={<ProductPage />} />
              <Route path="/products/:category/:subcategory" element={<ProductPage />} />
              <Route path="/brand/:id" element={<BrandPage />} />
              <Route path="/brand/:brandId/product/:productId" element={<BrandProductDetails />} />
              <Route path="/product/:id" element={<ProductDetails products={products} />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<h2>404 - Page Not Found</h2>} />
            </Routes>
          </main>
        </Suspense>
        <Footer />
        <ScrollToTopButton />
      </div>
    </Router>
  );
}

export default App;
