// src/components/Loader/Loader.tsx
import React from 'react';
import './Loader.scss';

const Loader: React.FC = () => (
  <div className="loader">
    <div className="spinner"></div>
  </div>
);

export default Loader;
