import React, { useState } from 'react';
import './Footer.scss';
import FacebookIcon from '../../assets/icons/facebook color.png';
import InstagramIcon from '../../assets/icons/instagram color.png';
import YoutubeIcon from '../../assets/icons/youtube color.png';
import WhatsappIcon from '../../assets/icons/whatsapp color.png';

const Footer: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const openPopup = (content: string) => {
    setPopupContent(content);
    setShowPopup(true);
  };

  const closePopup = () => setShowPopup(false);

  return (
    <div className="footer-wrapper">
      <div className="footer-extra">
        <div className="menu-list">
          <h3>Menu</h3>
          <ul>
            <li><a href='/'>Home</a></li>
            <li><a href='/'>About Us</a></li>
            <li><a href='/'>Services</a></li>
            <li><a href='/'>Products</a></li>
            <li><a href='/'>Contact</a></li>
          </ul>
        </div>

        <div className="contact-info">
          <h3>Contact Info</h3>
          <p>Phone: +91 9901876682</p>
          <p>Email: contact@easylifeenterprises.in</p>
        </div>

        <div className="policy-links">
          <h3>Policy</h3>
          <p onClick={() => openPopup('privacy')}>Privacy Policy</p>
          <p onClick={() => openPopup('terms')}>Terms & Conditions</p>
        </div>
        <div className="socialicons">
            <h3>Follow us</h3>
            <div className='Footer-social-icons'><a href="https://wa.me/message/MYSUG2OU4EFBN1" target="_blank" rel="noopener noreferrer">
              <img src={WhatsappIcon} className="social-icon" />
            </a>
            <a href="https://www.instagram.com/easylifeenterprises/profilecard/?igsh=MTh1MnE1aG95N2E5cA==" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} className="social-icon" />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={FacebookIcon} className="social-icon" />
            </a>
            <a href="https://youtube.com/@easylifeenterprises2536?si=0ebLPqbNxjVMmO_q" target="_blank" rel="noopener noreferrer">
              <img src={YoutubeIcon} className="social-icon" />
            </a></div>
          </div>
      </div>

      <footer className="footer">
        <p>&copy; 2024 Easylife Enterprise. All rights reserved. Design & Developed by <a href='https://www.prajnansoftwares.com/aboutus' target='_blank'>Prajnan Software Pvt Ltd.</a></p>
      </footer>

      {showPopup && (
        <div className="popup-modal">
          <div className="popup-content">
            <span className="close-button" onClick={closePopup}>&times;</span>
            <h2>{popupContent === 'privacy' ? 'Privacy Policy' : 'Terms & Conditions'}</h2>
            <p>
              {popupContent === 'privacy' ? 'Privacy Policy for Easylife Enterprise is committed to protecting the privacy of its users. We do not collect, store, or share any personal data from visitors on our website. Our website is designed purely for informational purposes, showcasing our products and services to assist users in understanding what we offer If you have any questions regarding this policy, please contact us.' : 'Welcome to Easylife Enterprise. By accessing or using our website, you agree to comply with the following terms and conditions. Please read them carefully.The content on this website is provided for informational purposes only. We reserve the right to modify or remove content at any time without prior notice.Our website may contain links to external sites. Easylife Enterprise is not responsible for the content or practices of these third-party sites.We may update these terms periodically. Continued use of the website after such changes constitutes acceptance of the revised terms.'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;


